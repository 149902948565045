import React, { useState, useEffect } from 'react';
import './ScheduleItemDetail.css';

const ScheduleItemDetail = ({ event, onBackClick, guestsData }) => {
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (guestsData) {
      const eventGuests = event.guests.map(guestId =>
        guestsData.find(guest => guest.id === guestId)
      );
      setGuests(eventGuests);
      setLoading(false);
    }
  }, [event, guestsData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    setError(error);
    return <div>Error loading guests data: {error.message}</div>;
  }

  return (
    <div className="schedule-item-detail">
      <button className="close-button" onClick={onBackClick}>X</button>
      <h2>{event.event}</h2>
      <p><strong>Time:</strong> {event.time}</p>
      <p><strong>Location:</strong> {event.location}</p>
      <p><strong>Categories:</strong> {event.tags.join(', ')}</p>
      <p>{event.description}</p>
      {guests.length > 0 && (
        <>
          <h3>Featured Guests:</h3>
          <div className="guest-list">
            {guests.map(guest => (
              <div key={guest.id} className="guest-item">
                <img src={guest.imgUrl} alt={guest.name} className="guest-image" />
                <p>{guest.name}</p>
              </div>
            ))}
          </div>
        </>
      )}
      <button className="back-button" onClick={onBackClick}>Close</button>
    </div>
  );
};

export default ScheduleItemDetail;
