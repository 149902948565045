import React from 'react';
import '../assets/css/styles.css';
import Header from './Header';

export default function Home() {
  return (
    <div className="homeBlock">
      <Header text="VAMPIRE FAN WEEKEND" />
      <div className='blob-wrapper'>
        <div className="blob-content">
          <center>
   <div className="dStrong">June 21-23, 2024</div>
        Hanover Marriott Hotel<br/>
        1401 Route 10 East<br/>
        Whippany, NJ 07981<br/>
        <br/>
        Join Ian Somerhalder, Paul Wesley and more guests in Whippay, NJ for non-stop programming, events, live entertainment and more!<br/>
        </center>
        </div>
      </div>
    </div>
  );
}