import React from 'react';
import moment from 'moment';
import './ScheduleTabs.css';

const ScheduleTabs = ({ dates, selectedDate, onDateSelect }) => {
  return (
    <div className="tabs">
      {dates.map(date => (
        <div
          key={date}
          className={`tab ${date === selectedDate ? 'active' : ''}`}
          onClick={() => onDateSelect(date)}
        >
          <div>{moment(date, 'MM-DD-YYYY').format('MMM D')}</div>
          <div>{moment(date, 'MM-DD-YYYY').format('ddd')}</div>
        </div>
      ))}
    </div>
  );
};

export default ScheduleTabs;
