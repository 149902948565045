import React, { useState, useEffect } from 'react';
import GuestsList from './GuestsList';
import Header from './Header';

export default function Guests({ guestsData }) {
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (guestsData) {
      setGuests(guestsData);
      setLoading(false);
    }
  }, [guestsData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    setError(error);
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <div className='blackBackground'>
      <Header text="Special Guests" />
      <GuestsList guests={guests} />
    </div>
  );
}
