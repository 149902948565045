import React, { useEffect } from 'react';
import GuestDetail from './GuestDetail';
import './GuestsList.css';

const GuestsList = ({ guests, isActiveTab }) => {
  const [selectedGuest, setSelectedGuest] = React.useState(null);

  useEffect(() => {
    if (isActiveTab) {
      setSelectedGuest(null);
    }
  }, [isActiveTab]);

  const handleGuestClick = guest => {
    setSelectedGuest(guest);
  };

  const handleBackClick = () => {
    setSelectedGuest(null);
  };

  return (
    <div className="guests-list">
      {selectedGuest ? (
        <GuestDetail guest={selectedGuest} onBackClick={handleBackClick} />
      ) : (
        guests.map(guest => (
          <div
            key={guest.id}
            className="guest-item"
            onClick={() => handleGuestClick(guest)}
          >
            <img
              className="guest-image"
              src={guest.imgUrl}
              alt={guest.name}
            />
            <span className="guest-name">{guest.name}</span>
          </div>
        ))
      )}
    </div>
  );
};

export default GuestsList;
