import { useEffect, useRef } from 'react';
import env from "react-dotenv";

const DataFetcher = ({ onDataLoaded }) => {
  const onDataLoadedRef = useRef(onDataLoaded);

  useEffect(() => {
    onDataLoadedRef.current = onDataLoaded;
  }, [onDataLoaded]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const scheduleResponse = await fetch(env.SCHEDULE_URI);
        const scheduleData = await scheduleResponse.json();

        const faqResponse = await fetch(env.FAQ_URI);
        const faqData = await faqResponse.json();

        const guestsResponse = await fetch(env.GUESTS_URI);
        const guestsData = await guestsResponse.json();

        onDataLoadedRef.current({
          scheduleData: scheduleData.data,
          faqData: faqData.data,
          guestsData: guestsData.data,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Schedule re-fetching as needed
    const scheduleInterval = setInterval(fetchData, env.REACT_APP_SCHEDULE_REFRESH_INTERVAL * 60000);
    const faqInterval = setInterval(fetchData, env.REACT_APP_FAQ_GUESTS_REFRESH_INTERVAL * 3600000);
    const guestsInterval = setInterval(fetchData, env.REACT_APP_FAQ_GUESTS_REFRESH_INTERVAL * 3600000);

    return () => {
      clearInterval(scheduleInterval);
      clearInterval(faqInterval);
      clearInterval(guestsInterval);
    };
  }, []);

  return null;
};

export default DataFetcher;
