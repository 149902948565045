import React from "react";
import DOMPurify from "dompurify";

const FAQ = ({ faq, index, toggleFAQ }) => {
  const sanitizedAnswer = DOMPurify.sanitize(faq.answer);

  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">{faq.question}</div>
      <div className="faq-answer" dangerouslySetInnerHTML={{ __html: sanitizedAnswer }}></div>
    </div>
  );
};

export default FAQ;
