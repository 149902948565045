import React from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import DOMPurify from 'dompurify';
import './GuestDetail.css';

const GuestDetail = ({ guest, onBackClick }) => {
  const sanitizedBio = DOMPurify.sanitize(guest.bio);

  return (
    <div className="guest-detail blackBackground">
      <button className="close-button" onClick={onBackClick}>
        <FaSignOutAlt />
      </button>
      <img src={guest.imgUrl} alt={guest.name} className="guest-detail-image" />
      <h2 className="guest-detail-name">{guest.name}</h2>
      <div className="guest-detail-bio" dangerouslySetInnerHTML={{ __html: sanitizedBio }}></div>
      <button className="back-button" onClick={onBackClick}>Back</button>
    </div>
  );
};

export default GuestDetail;
