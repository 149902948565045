import React, { useState, useEffect } from 'react';
import './AdminPanel.css';
import Header from './Header';

export default function AdminPanel() {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/data/faq.json')
      .then(response => response.json())
      .then(data => {
        setFaqs(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching the FAQ data:', error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleInputChange = (index, field, value) => {
    const newFaqs = [...faqs];
    newFaqs[index][field] = value;
    setFaqs(newFaqs);
  };

  const saveChanges = () => {
    fetch('/data/faq.json', {
      method: 'PUT', // Adjust the method as needed (e.g., POST)
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: faqs }),
    })
      .then(response => response.json())
      .then(data => {
        alert('Data saved successfully');
      })
      .catch(error => {
        console.error('Error saving the data:', error);
        setError(error);
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <div className="blackBackground homeBlock">
      <Header text="Admin Panel - Edit FAQs" />
      <div className="faqs">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <input
              type="text"
              value={faq.question}
              onChange={(e) => handleInputChange(index, 'question', e.target.value)}
            />
            <textarea
              value={faq.answer}
              onChange={(e) => handleInputChange(index, 'answer', e.target.value)}
            />
            <label>
              Open:
              <input
                type="checkbox"
                checked={faq.open}
                onChange={(e) => handleInputChange(index, 'open', e.target.checked)}
              />
            </label>
          </div>
        ))}
      </div>
      <button onClick={saveChanges}>Save Changes</button>
    </div>
  );
}
