import React, { useState, useEffect, useRef } from 'react';
import './ScheduleList.css';
import ScheduleItemDetail from './ScheduleItemDetail';
import moment from 'moment';

const ScheduleList = ({ schedule, guestsData, selectedDate }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const scheduleListRef = useRef(null);
  const [currentBlock, setCurrentBlock] = useState('');

  // Sort schedule by date and time
  const sortedSchedule = [...schedule].sort((a, b) => {
    const dateComparison = moment(a.date, 'MM/DD/YY').diff(moment(b.date, 'MM/DD/YY'));
    if (dateComparison !== 0) return dateComparison;
    return moment(a.time, 'h:mm A').diff(moment(b.time, 'h:mm A'));
  });

  const groupedSchedule = sortedSchedule.reduce((acc, item) => {
    (acc[item.time] = acc[item.time] || []).push(item);
    return acc;
  }, {});

  const handleBackClick = () => {
    setSelectedEvent(null);
  };

  useEffect(() => {
    if (selectedDate === moment().format('M/D/YY')) {
      const currentTime = moment().format('h:mm A');
      const times = Object.keys(groupedSchedule).sort((a, b) => moment(a, 'h:mm A') - moment(b, 'h:mm A'));
      const closestTimeIndex = times.findIndex(time => moment(currentTime, 'h:mm A').isBefore(moment(time, 'h:mm A')));
      const scrollToTime = closestTimeIndex === -1 ? times[times.length - 1] : times[Math.max(closestTimeIndex - 1, 0)];

      if (scrollToTime && scheduleListRef.current) {
        const elementToScrollTo = scheduleListRef.current.querySelector(`[data-time="${scrollToTime}"]`);
        if (elementToScrollTo) {
          setCurrentBlock(scrollToTime);
          elementToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }, [selectedDate, groupedSchedule]);

  return (
    <div className="schedule-list" ref={scheduleListRef}>
      {selectedEvent ? (
        <ScheduleItemDetail
          event={selectedEvent}
          onBackClick={handleBackClick}
          guestsData={guestsData}
        />
      ) : (
        Object.entries(groupedSchedule).map(([time, events]) => (
          <div key={time} className='schedule-section'>
            <div key={time} className={`schedule-time-group ${time === currentBlock ? 'current-blocks' : ''}`} data-time={time}>
              <h3>{time}</h3>
              {events.map(event => (
                <div key={event.id} className="schedule-item" onClick={() => setSelectedEvent(event)}>
                  <h4>{event.event}</h4>
                  <p>{event.location}</p>
                </div>
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ScheduleList;
