import React, { useState, useEffect } from 'react';
import ScheduleTabs from './ScheduleTabs';
import ScheduleList from './ScheduleList';
import Header from './Header';
import './Schedule.css';
import { FaFilter, FaTimes } from 'react-icons/fa';
import moment from 'moment';

const Schedule = ({ scheduleData, guestsData }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTag, setSelectedTag] = useState(null);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    if (scheduleData.length > 0) {
      const currentDate = moment().format('M/D/YY');
      const eventDates = scheduleData.map(item => item.date);
      if (eventDates.includes(currentDate)) {
        setSelectedDate(currentDate);
      } else {
        setSelectedDate(eventDates[0]);
      }
    }
  }, [scheduleData]);

  const dates = [...new Set(scheduleData.map(item => item.date))];
  const tags = [...new Set(scheduleData.flatMap(item => item.tags))];

  const filteredSchedule = scheduleData.filter(item =>
    item.date === selectedDate && (selectedTag ? item.tags.includes(selectedTag) : true)
  );

  const clearFilter = () => setSelectedTag(null);

  return (
    <div className=''>
      <Header text="Event Schedule" />
      <div className="tabs-container">
        <ScheduleTabs dates={dates} selectedDate={selectedDate} onDateSelect={setSelectedDate} />
        <div className='filter-wrapper'>
          <div className="filter-container">
            <FaFilter className="filter-icon" onClick={() => setShowFilter(!showFilter)} />
            {selectedTag && <FaTimes className="clear-filter-icon" onClick={clearFilter} />}
          </div>
        </div>
      </div>
      {showFilter && (
        <div className="filter-list">
          {tags.map(tag => (
            <div key={tag} className={`filter-item ${selectedTag === tag ? 'selected' : ''}`} onClick={() => setSelectedTag(tag)}>
              {tag}
            </div>
          ))}
          <div className={`filter-item ${selectedTag === null ? 'selected' : ''}`} onClick={clearFilter}>
            Reset
          </div>
        </div>
      )}
      <div className="schedule-list-container">
        <ScheduleList schedule={filteredSchedule} guestsData={guestsData} selectedDate={selectedDate} />
      </div>
    </div>
  );
};

export default Schedule;
