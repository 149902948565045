import React, { useState, useEffect } from 'react';
import FAQ from './FAQ';
import '../assets/css/styles.css';
import Header from './Header';

export default function Info({ faqData }) {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (faqData) {
      setFaqs(faqData);
      setLoading(false);
    }
  }, [faqData]);

  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      })
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    setError(error);
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <div className="blackBackground homeBlock">
      <Header text="Frequently Asked Questions" />
      <div className="faqs">
        {faqs.map((faq, index) => (
          <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
}
