import React, { useState } from 'react';
import env from "react-dotenv";

import DataFetcher from './components/DataFetcher';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FaHome, FaCalendar, FaInfoCircle, FaTicketAlt, FaCamera } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import Home from './components/Home';
import Info from './components/Info';
import Guests from './components/Guests';
import Schedule from './components/Schedule';
import AdminPanel from './components/AdminPanel'; // Import the AdminPanel component
import AdminLanding from './components/AdminLanding'; // Import the AdminLanding component
import Photos from './components/Photos';
import './App.css';
import './fonts.css'; // Import the fonts.css file


const App = () => {
  const [activeTab, setActiveTab] = useState(0);
  
  const [data, setData] = useState({
    scheduleData: null,
    faqData: null,
    guestsData: null,
  });

  const handleDataLoaded = (loadedData) => {
    setData(prevData => ({
      ...prevData,
      ...loadedData,
    }));
  };

  const handleTabClick = (index) => {
    if (index === 5) {
      window.open(env.TICKET_LINK, "ticketing");
    } else {
      setActiveTab(index);
    }
  };

  const renderTabPanel = () => {
    switch (activeTab) {
      case 0:
        return <Home />;
      case 1:
        return <Schedule scheduleData={data.scheduleData} guestsData={data.guestsData}/>;
      case 2:
        return <Guests guestsData={data.guestsData}/>;
      case 3:
        return <Info faqData={data.faqData}/>;
      case 4:
        return <Photos />;
      default:
        return null;
    }
  };

  return (
    <div className="App">
       <DataFetcher onDataLoaded={handleDataLoaded} />
    <Router>
      <Routes>
        <Route path="/ce_admin" element={<AdminLanding />} />
        <Route path="/admin" element={<AdminPanel />} />
        <Route path="/" element={
          <div>
            <div className="header" />
            <div className="container">
              <div className="tabs">
                <div className="tab-panels">
                  {renderTabPanel()}
                </div>
                <div className="tab-list">
                  <div className={`tab ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>
                    <FaHome />
                    <span className="tab-text">Home</span>
                  </div>
                  <div className={`tab ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
                    <FaCalendar />
                    <span className="tab-text">Schedule</span>
                  </div>
                  <div className={`tab ${activeTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2)}>
                    <IoPerson />
                    <span className="tab-text">Guests</span>
                  </div>
                  <div className={`tab ${activeTab === 4 ? 'active' : ''}`} onClick={() => handleTabClick(4)}>
                    <FaCamera />
                    <span className="tab-text">Photos</span>
                  </div>
                  <div className={`tab ${activeTab === 3 ? 'active' : ''}`} onClick={() => handleTabClick(3)}>
                    <FaInfoCircle />
                    <span className="tab-text">Info</span>
                  </div>
                  <div className="tab" onClick={() => handleTabClick(5)}>
                    <FaTicketAlt />
                    <span className="tab-text">Tickets</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        } />
      </Routes>
    </Router>
    </div>

  );
};

export default App;
