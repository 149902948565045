import React, { useState, useEffect } from 'react';
import './Header.css';

const Header = ({ text }) => {
  const [tickerText, setTickerText] = useState('');

  const fetchTickerText = () => {
    fetch('https://mobile.creationent.com/thirds/thirds.php')
      .then(response => response.json())
      .then(data => {
        if (data.text) {
          setTickerText(data.text);
        } else {
          setTickerText('');
        }
      })
      .catch(error => {
        console.error('Error fetching the ticker text:', error);
        setTickerText('');
      });
  };

  useEffect(() => {
    // Initial fetch
    fetchTickerText();

    // Set interval to fetch every 60 seconds
    const interval = setInterval(() => {
      fetchTickerText();
    }, 60000);

    // Clear interval on cleanup
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="header-container" style={styles.header}>
      <h2 className="header-text">{text}</h2>
      {tickerText && (
        <div className="ticker-container">
          <div className="ticker">
            <div className="ticker-text">{tickerText}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;

const styles = {
  header: {
    marginBottom: '20px',
    padding: '10px',
    borderBottom: '1px solid #ccc',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
};
